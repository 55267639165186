import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'

const UgdymasPage = () => {
  const pageTitle = 'Ugdymas'
  const pageCards = [
    {
      title: 'UGDYMAS GAMTOJE IR SU GAMTA',
      body: (
        <span>
          <p>
            <i>
              „Gamta turi unikalią savybę – nuolatos stebinti, o tai taip
              reikalinga mažajam atradėjui“
            </i>
          </p>
          <p>
            Tikime, kad harmoningam vaiko asmenybės ugdymui glaudus santykis su
            gamta yra itin svarbus. Remiantis tiek klasikinėmis, tiek
            moderniausiomis ugdymo sistemomis skatiname vaiko meilę gamtai ir
            turiningą veiklą joje. Apie vaiko ugdymą gamtoje jau XVIII a.
            kalbėjo švietėjas Ž.Ž Ruso, apie tai kalba ir šiandieniniai XXI a.
            ugdymo filosofai (F. Friobel, R. Štainer, L. Malaguzzi). Remdamiesi
            jų idėjomis išskyrėme 7 svarbiausius ugdymo gamtoje ir su gamta
            principus, kuriuos taikome:
          </p>

          <h3>1. NUO &nbsp; GIMIMO</h3>
          <p>
            Vaikas ryšį su pasauliu užmezga tik gimęs. Vėliau šis santykis
            gilinamas pažįstant pasaulį per gamtą. Skatinama gamtos nebijoti, o
            mylėti. Vaikams leidžiama imituoti gamtos motyvus – paukščių garsus,
            žvėrių riaumojimą (L. Malaguzzi).
          </p>
          <p>
            <i>Laviname MĄSTYMĄ, VAIZDUOTĘ, FANTAZIJĄ</i>
          </p>

          <h3>2. PASAULIO &nbsp; PAŽINIMAS</h3>
          <p>
            Jei vaikas pažins gamtą, tai supras ir suvoks visą likusį pasaulį.
            Gamtoje ugdomi vaiko pažintiniai gebėjimai. Jis gali uostyti gėlę,
            įsiminti jos kvapą, ragauti uogas, jausti vėjo dvelksmą. Taip vaikas
            pažįsta pasaulį, suvokia jį kaip gyvą, išmoksta švelnumo, empatijos
            (Ž.Ž Ruso).{' '}
          </p>
          <p>
            <i>Laviname DĖMESIO SUTELKTĮ, SUVOKIMĄ, ĮSIMINIMĄ, SAMPROTAVIMĄ</i>
          </p>

          <h3>3. GRYNAS &nbsp; ORAS</h3>
          <p>
            Vaiko ugdymas sėkmingiausias palankioje aplinkoje. Grynas oras –
            itin svarbus jos veiksnys. Vaikai skatinami kuo daugiau laiko
            praleisti gryname ore, pajausti orų kaitą (R. Štainer).{' '}
          </p>
          <p>
            <i>Laviname ADAPTACIJĄ NAUJOJE APLINKOJE, IMUNINĘ SISTEMA</i>
          </p>

          <h3>4. GAMTA &nbsp;–&nbsp; GERIAUSIA &nbsp; MOKYTOJA</h3>
          <p>
            Gamta niekada žmogaus neapgaudinėja, todėl mažylis pažįsta tikrą
            pasaulį. Gamtai sezoniškai kintant, vaikas suvokia, kad ji gyva,
            dinamiška. Vaikas nuolat mato natūralius, neperdarytus reiškinius ir
            objektus. Toks pažinimas – per patyrimą – yra unikalus, neatstojamas
            perskaityta ar išgirsta informacija (Ž.Ž Ruso).
          </p>
          <p>
            <i>
              Laviname SPALVŲ, FORMŲ, DYDŽIŲ, PLASTIŠKUMO SUVOKIMĄ, KALBĄ,
              ŽODYNĄ, MINČIŲ RAIŠKĄ
            </i>
          </p>

          <h3>5. RYŠYS &nbsp; SU &nbsp; GAMTA</h3>
          <p>
            Iš aplinkos gautą informaciją vaikas savaip interpretuoja ir
            sistemina. Puoselėjama pagarba vaikui, išlaisvinami jo natūralūs
            pojūčiai, skatinama saviraiška. Vaikai gali ropoti, lipti,
            susipurvinti, įklimpti ir kitaip išlaisvinti savo judesius (L.
            Malaguzzi).{' '}
          </p>
          <p>
            <i>
              Laviname STAMBIĄJĄ MOTORIKĄ (viso kūno raumenis, fizinę galią,
              koordinaciją)
            </i>
          </p>

          <h3>6. ATRADĖJAS</h3>
          <p>
            Vaiko asmenybės ugdymas susideda iš mažų atradimų, kuriuos vaikas
            padaro pats. Gamta yra tinkama erdvė atradimams. Pedagogai yra tik
            vaiko patarėjai, stebėtojai, saugotojai – kad didieji atradimai būtų
            ramūs (Ž.Ž Ruso).
          </p>
          <p>
            <i>Laviname POJŪČIUS, KRITINĮ MĄSTYMĄ, APLINKOS SUVOKIMĄ</i>
          </p>

          <h3>7. GAMTOS &nbsp; TAUSOJIMAS</h3>
          <p>
            Vaikas yra priklausomas nuo aplinkos, kurioje gyvena, todėl turi
            išmokti ją tausoti. Svarbu mokyti rūpintis savo aplinka, formuoti
            ekologijos sąvoką (F. Friobel).{' '}
          </p>
          <p>
            <i>Laviname KŪRYBIŠKUMĄ, ESMINIŲ GAMTOS DĖSNIŲ SUVOKIMĄ</i>
          </p>
        </span>
      )
    },
    {
      title: 'ETNOKULTŪRINIS LAVINIMAS',
      body: (
        <span>
          <p>
            Tikime, kad harmoninga asmenybė gali formuotis tik esant glaudžiam
            santykiui su sava etnokultūra. Vaikai savo tapatumo ieško
            mėgdžiodami tai, kas yra jų aplinkoje, gyvenamoje vietoje. Jie
            mėgsta jausti, kad kažkam priklauso. Susipažindami su įvairiomis
            tradicijomis ir papročiais vaikai išmoksta mylėti savo kraštą, nes
            jaučiasi savi, priimti. Vaiko etnokultūriniame ugdyme svarbiausia:
          </p>

          <h3>1. IŠKARTOS &nbsp; – &nbsp; ĮKARTĄ</h3>
          <p>
            Iš kartos į kartą perduodama tautos dvasinė patirtis, pilna įvairių
            tradicijų ir liaudies meno formų, ilgus šimtmečius padėjo lavinti
            mažuosius. Skatinama pažinti savos tautos šaknis per liaudies
            žaidimus, dainas, pasakas.
          </p>
          <p>
            <i>Laviname vaiko VAIZDUOTĘ, MENINĘ RAIŠKĄ, MUZIKALUMĄ</i>
          </p>

          <h3>2. MEILĖ &nbsp; SAVO &nbsp; KRAŠTUI</h3>
          <p>
            Vaikų etnokultūrinė patirtis pamažu subrandina nuoširdų santykį su
            savo kraštu, jo praeitimi ir dabartimi, formuoja tam tikras
            vertybines nuostatas.{' '}
          </p>
          <p>
            <i>
              Laviname vaiko ŽODYNĄ, EMOCIJŲ RAIŠKĄ IR EMOCINĮ INTELEKTĄ,
              BENDRUOMENIŠKUMĄ
            </i>
          </p>

          <h3>3. KITOS &nbsp; KULTŪROS</h3>
          <p>
            Vaikui savo etnokultūrą padeda suvokti ir kitų kultūrų pažinimas.
            Lygindami savo ir kitas kultūras vaikai gali pajausti, kuo jie
            panašūs ir kuo skiriasi nuo kitų.
          </p>
          <p>
            <i>
              Laviname vaiko ETNINĘ IR KULTŪRINĘ TOLERANCIJĄ, PASAULIO ĮVAIROVĖS
              SUVOKIMĄ
            </i>
          </p>
        </span>
      )
    },
    {
      title: 'PSICHOLOGINĖS VAIKO RAIDOS TEORIJOS',
      body: (
        <span>
          <p>
            Vaiko ugdymą grindžiame psichologinėmis žiniomis. Asmenybės
            skleidimąsi skatinantis ugdymas paremtas vaiko raidos dėsningumų
            žinojimu, teikiant svarbos ir asmenybės individualumui. Pagal vieną
            žymiausių asmenybės raidos tyrėjų, psichologą E. Erikosną, žmogus
            per gyvenimą pereina aštuonis asmenybės raidos tarpsnius.
            Kiekviename iš jų žmogus tarsi turi ,,du kelius“, kuriais gali
            pasukti: asmenybės brandumo arba nebrandumo link. Ikimokykliniame
            amžiuje svarbiausi šie tarpsniai:
          </p>

          <h3>
            1. <b>PASITIKĖJIMAS</b> &nbsp; vs. &nbsp; NEPASITIKĖJIMAS (nuo
            gimimo iki 1 m.)
          </h3>
          <p>
            Jei vaikas patiria meilę, globą ir rūpestį, įgyja pasitikėjimą
            kitais. Jis tarsi nutipena pasitikėjimo keliu. Jei mažai rūpinamasi
            vaiku, jis nepatiria meilės, saugumo, formuojasi baikštumas,
            nepasitikėjimas aplinkiniais žmonėmis ir visu pasauliu. Vaikas tarsi
            nutipena nepasitikėjimo keliu.{' '}
          </p>

          <h3>
            2. <b>AUTONOMIJA</b> &nbsp; vs. &nbsp; GĖDA (1–3 m.)
          </h3>
          <p>
            Vaikas siekia viską daryti pats, bando pažinti pasaulį savo jėgomis
            (mokosi užsirišti batų raištelius, pasikloti lovytę ir pan.). Jei
            leidžiama vaikui savarankiškai atlikti tuos veiksmus, formuojasi
            pasitikėjimas savimi – ,,aš galiu“. Vaikas sėkmingai tipena
            autonomijos keliu. Ir priešingai – jei vaikas nuolat drausminamas,
            jam neleidžiama pačiam savarankiškai atlikti veiksmų, abejojama juo
            – nutipenama gėdos keliu.{' '}
          </p>

          <h3>
            3. <b>INICIATYVA</b> &nbsp; vs. &nbsp; KALTĖ (3-6 m.)
          </h3>
          <p>
            Jei vaikas auga jausdamas pasitikėjimą savimi, jis pradeda rodyti
            iniciatyvą – nori atlikti daugelį veiksmų pats. Aplinka turi vaiką
            skatinti, tuomet jis nutipens iniciatyvumo keliu. Jei vaikas
            neskatinamas, įvairios veiklos jam draudžiamos (pvz. padėti gaminti
            maistą), formuojasi kaltės jausmas – „esu mažas ir nieko nesugebu“.
            Vaikas tuomet nutipena kaltės keliu.{' '}
          </p>

          <p>
            <i>
              Mūsų tikslas su tėvelių pagalba skatinti vaikus tipenti
              pozityviuoju keliu per asmenybės raidą. Žinoma, gyvenime, nėra
              dviejų griežtų kelių, tai labiau teorinis skirstymas, tačiau
              kryptingumas vis dėlto įžvelgiamas stebint besiformuojančią
              asmenybę.{' '}
            </i>
          </p>
        </span>
      )
    },
    {
      title: 'SAVAITĖS RITMAS',
      body: (
        <span>
          <h3>PIRMADIENIS – KALBOS DIENA</h3>
          <p>
            Gebėjimas bendrauti su kitais žmonėmis - kalbėti ir klausyti,
            skaityti ir rašyti - yra labai svarbi prielaida vaikui sėkmingai
            ugdytis ir mokytis. KLAUSYMAS ir KALBĖJIMAS yra įgūdžiai, kurių
            reikia socialiai sąveikaujant su aplinkiniais. Vaikai, kurių žodynas
            turtingas, lengviau pradeda skaityti. Tad kalbėjimo mūsų grupėje
            galima išgirsti daug. Pirmadieniais su vaikais aptariame įsiminusius
            poilsio dienelių įvykius. Žaidžiame garsais, klausa tyrinėdami
            eiliuotų tekstų rimus, deklamuodami eilėraščius, dainuodami dainas.
          </p>
          <p>
            RAŠYMAS ir SKAITYMAS yra lygiagretūs procesai. Skaitymo tikslas yra
            suprasti raštu išreikštą mintį. Keverzodamas ant popieriaus ir
            pasakodamas, ką tai reiškia, vaikas pradeda suvokti, kad raštu yra
            perduodama žinia. Jis palengva ima domėtis abėcėle ir pažinti
            raides, iš kurių svarbiausios raidės yra jo vardo raidės!
          </p>
          <p>
            &#09;&#09;<i>Aplinka.</i> Pirmadieniais mūsų grupės aplinka
            pasipildo knygelėmis ir aktualiais paveikslėliais. Erdvinės raidės,
            įvairių dydžių kortelės su raidėmis ir stalo žaidimai, kviečia mus
            atrasti jas. Pirma savaitės diena atsineša <u>naują pasaką</u>,
            kurią nagrinėjame visą savaitę.
          </p>
          <p>
            &#09;&#09;<i>Rankų darbeliai.</i> Pirmadienį vaikams siūlome piešti
            ir tyrinėti įvairias rašymo bei piešimo priemones, dažus.
          </p>

          <h3>ANTRADIENIS – GAMTOS DIENA</h3>
          <p>
            Maži vaikai yra tikri tyrinėtojai! Todėl skatiname juos apžiūrėti
            įvairius aplinkos daiktus - įprastus ir dar neregėtus, laiko
            paliestus, senovinius ir modernius. Stebime orų pasikeitimus, žymime
            juos orų kalendoriuje. Pavasarį sėsime ir auginsime augalus – ant
            palangės ir darže. Mokysimės rūpintis jais. Lauke galėsime stebėti
            vabzdžius, sliekus, sraiges, paukščius ir kitokius gyvius,
            patekusius mūsų akiratin. Tądien skanausime daržovių, vaisių – kad
            gamtiniuose tyrinėjimuose dalyvautų visos juslės. Mokysimės rūpintis
            gyvąja gamta, džiaugsimės jos įvairove ir grožiu. Darželyje
            atsakingai naudojamės Žemės turtais: vandeniu, uolienomis, smėliu,
            gamtos dovanėlėmis. Stebėdami suaugusius išmokstame rūšiuoti
            atliekas, kai kurias jų perdirbame kūrybiškai. Antradieniais
            organizuosime netolimus žygius prie įdomesnių gamtos objektų: ežero
            ar miškelio.
          </p>
          <p>
            &#09;&#09;<i>Aplinka.</i> Antradieniais mūsų grupės aplinka
            praturtinta <u>gamtos dovanėlėmis</u>: kankorėžiais, kelmeliais,
            kriauklytėmis, akmenėliais ir kt. Parūpiname vaikams įrankių ir
            priemonių tyrinėjimamas: didinamųjų stiklų, svarstyklių, pipečių,
            indelių, kolbelių, formelių ir t.t.{' '}
          </p>
          <p>
            &#09;&#09;<i>Rankų darbeliai.</i> Antradieniais mes <u>lipdome</u>!
            Tad molis, druskos tešla, vaškas – yra gyvos medžiagos kūrybai.{' '}
          </p>

          <h3>TREČIADIENIS – GUDRUČIO DIENA</h3>
          <p>
            Nereikia būti mokslininku, norint supažindinti vaiką su
            elementariais mokslo dėsniais. Daugybė kasdien buityje atliekamų
            darbų yra susiję su matematika: rūšiuoti daiktus, atpažinti juos
            pavyzdyje, lyginti pagal dydį, spalvą ar formą, matuoti, skaičiuoti.
            Tai diena, kai vaikams siūlome atlikti <u>bandymus</u>,
            eksperimentus ar žaisti loginius žaidimus.
          </p>
          <p>
            Ikimokyklinuko elementarių matematikos vaizdinių turinį sudaro{' '}
            <u>
              kiekio ir skaičiaus, geometrinių figūrų, dydžio suvokimas, pradinė
              orientacija erdvėje ir laike
            </u>
            . Žaidžiant susidaro sąlygos skaičiuoti, matuoti, nustatyti daikto
            formą, dydį, rasti jį tam tikroje vietoje, orientuotis laike,
            vartoti atitinkamas sąvokas: mokėti taisyklingus skaitmenų,
            geometrinių figūrų pavadinimus, nusakyti įvairaus dydžio
            kategorijas, įvardinti erdvinę padėtį bei pasakyti pirmuosius laiko
            matavimo vienetus.
          </p>
          <p>
            &#09;&#09;<i>Aplinka.</i> Trečiadieniais grupės aplinkoje matome:
            geometrines formas, skaitmenis, birius daiktus - skaičiavimo
            pratyboms, <u>dėžes</u> ir kalades - konstravimo žaidimams.
            Parūpiname priemonių ir prietaisų, skirtų lyginti ir matuoti.
            Technologiniai prietaisai ir įrankiai taip pat laukiami mūsų
            aplinkoje: skaičiuotuvas, termometras ir kt.
          </p>
          <p>
            &#09;&#09;<i>Rankų darbeliai.</i> Trečiadieniais dirbame{' '}
            <u>darbelius iš popieriaus</u>! Plėšome ir karpome, lankstome ir
            aplikuojame, kuriame erdvines popieriaus skulptūras. Todėl mums
            reikia: visokių rūšių ir spalvų popieriaus, folijos, klijų, žirklių
            ir t.t. Bendraujame su etnografijos mokytoja.
          </p>

          <h3>KETVIRTADIENIS – ETNOGRAFIJOS DIENA</h3>
          <p>Ši diena skirta mūsų mažųjų tautinei savimonei ugdyti.</p>
          <p>
            Tautinės savimonės puoselėjimas susideda iš susipažinimo ir
            nagrinėjimo tradicijų, papročių, amatų ir darbų, savo šalies
            istorijos – vaikai supažindinami (pagal amžių) su istorine kultūrine
            Lietuvos praeitimi, skatinamas jų žingeidumas pažinti savo šalį,
            atrasti jos savitumą. Taip pat neatsiejamai ugdoma pagarba, meilė ir
            rūpestingumas gamtai ir gyvūnijai, susipažįstama su Lietuvos
            gyvūnija ir augmenija, jos ypatybėmis.
          </p>
          <p>
            Ugdant vaiko pagarbą savo šaliai ir jos kultūrai, praktiškai vaikai
            pratinami prie bendruomeniškumo, pagarbos bendruomenei (grupės,
            darželio), todėl šią dieną taip pat prisimenami mūsų grupės
            bendruomenės taisyklės, ritualai ir kt.
          </p>
          <p>
            Su padavimais, pasakomis, sakmėmis, legendomis vaikai susipažins su
            mitologija, senosiomis apeigomis.
          </p>
          <p>
            Tautosakos skrynelė pažers vaikams mįslių, minklių, patarlių,
            priežodžių. Su vaikais žaisime lietuvių liaudies žaidimus,
            dainuosime lietuvių liaudies dainas, klausysimės liaudies muzikos.
          </p>
          <p>
            &#09;&#09;<i>Aplinka.</i> Vaikai skatinami domėtis kultūros paveldu
            įvairiomis formomis: vaizdinėmis priemonėmis, garso ir vaizdo
            įrašais, informaciniais lediniais, sieniniais žemėlapiais.
          </p>
          <p>
            &#09;&#09;<i>Rankų darbeliai.</i> vaikai įvairiomis technikomis
            (piešimas, tapyba, aplikacija ir kt.) bando atkurti senuosius
            raštus, simbolius ir kartu nagrinėja jų reikšmes, prasmes, atranda
            ir kuria savo kūriniuose šalies simbolius, šalies kraštovaizdį,
            augmeniją, gyvūniją
          </p>

          <h3>PENKTADIENIS – ŽAISLO DIENA</h3>
          <p>
            Ši diena skiriama savaitės įvykiams aptarti ir užsiėmimams,
            priklausomai nuo metų laikų, švenčių ar kasdieninių darželio įvykių.
            Galima atsinešti vieną žaislą ar žaidimą iš namų ir juo dalintis su
            kitais vaikais.
          </p>
          <p>
            &#09;&#09;<i>Aplinka.</i> Penktadieniais grupės aplinka ramesnė,
            mažiau stimuliuojanti. Klausomės raminančios muzikos.
          </p>
          <p>
            &#09;&#09;<i>Rankų darbeliai.</i> Penktadieniais piešiame savaitės
            pasakos herojų arba ką norisi. Vaikams siūlome flomasterius, pastelę
            ar akvarelinius pieštukus.
          </p>
        </span>
      )
    }
  ]

  return (
    <MyPage pageType="accordion" pageTitle={pageTitle} pageCards={pageCards} />
  )
}

export default UgdymasPage
